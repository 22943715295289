import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Group } from '../../../types/group';
import { TextField, Grid, Box } from '@mui/material';
import { useGroups } from '../../../contexts/groupsContext';

interface Props {
    groupData: Group | null;
    setGroupData: React.Dispatch<React.SetStateAction<Group | null>> | null;
}

export default function GroupSelect(props: Props) {
    const { groupData, setGroupData } = props;

    const { selectedGroup, groups, setSelectedGroup } = useGroups();

    const [options, setOptions] = useState<readonly Group[]>([]);
    const [inputValue, setInputValue] = useState<string>('');

    return (
        <Autocomplete
            sx={{ width: '100%' }}
            getOptionLabel={(option: Group) =>
                typeof option === 'string' ? option : option.name
            }
            filterOptions={(x) => x}
            options={groups ?? []}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={groupData ? groupData : selectedGroup}
            noOptionsText="No groups"
            onChange={(event: any, newValue: Group | null) => {
                setOptions(newValue ? [newValue, ...options] : options);
                !groupData && setSelectedGroup(newValue);

                if (setGroupData) groupData && setGroupData(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label="Select group" fullWidth />
            )}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;

                return (
                    <li key={key} {...optionProps}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                {' '}
                                <Box
                                    sx={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '50px',
                                        bgcolor: option.color
                                    }}
                                ></Box>
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    width: 'calc(100% - 44px)',
                                    wordWrap: 'break-word'
                                }}
                            >
                                <Box
                                    component="span"
                                    sx={{
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {option.name}
                                </Box>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
}
