import { db } from '../firebase/firebase';
import {
    collection,
    addDoc,
    writeBatch,
    doc,
    getDocs,
    deleteDoc,
    query,
    where,
    limit,
    orderBy,
    updateDoc
} from 'firebase/firestore';
import { GhostScreen } from '../types/ghost-screen';
import { GHOST_POINTS_COLLECTION } from '../utils/collection-names';

const saveGhostScreen = async (ghostPointData: GhostScreen) => {
    try {
        const docRef = await addDoc(
            collection(db, GHOST_POINTS_COLLECTION),
            ghostPointData
        );
    } catch (error) {
        console.error('Error adding ghost point:', error);
    }
};

const updateGhostScreen = async (
    id: string,
    updatedData: Partial<GhostScreen>
) => {
    try {
        const docRef = doc(db, GHOST_POINTS_COLLECTION, id);

        await updateDoc(docRef, updatedData);
    } catch (error) {
        console.error('Error updating ghost screen:', error);
    }
};

const saveManyGhostScreens = async (points: GhostScreen[]) => {
    try {
        const batch = writeBatch(db);
        const collectionRef = collection(db, GHOST_POINTS_COLLECTION);

        points.forEach((point) => {
            const newDocRef = doc(collectionRef);
            batch.set(newDocRef, point);
        });

        await batch.commit();
    } catch (error) {
        console.error('Error adding ghost points:', error);
    }
};

const getAllGhostScreens = async (): Promise<GhostScreen[]> => {
    try {
        const querySnapshot = await getDocs(
            collection(db, GHOST_POINTS_COLLECTION)
        );

        const ghostPoints = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
                id: doc.id,
                ...data
            };
        }) as unknown as GhostScreen[];

        return ghostPoints;
    } catch (error) {
        console.error('Error fetching ghost points:', error);
        return [];
    }
};

const deleteGhostScreen = async (screen_id: number) => {
    try {
        const ghostPointsRef = collection(db, GHOST_POINTS_COLLECTION);
        const q = query(ghostPointsRef, where('screen_id', '==', screen_id));

        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
            console.log('No ghost point found with the specified screen_id.');
            return;
        }

        querySnapshot.forEach(async (doc) => {
            await deleteDoc(doc.ref);
        });
    } catch (error) {
        console.error('Error deleting ghost point:', error);
    }
};

const deleteManyGhostScreens = async (points: GhostScreen[]) => {
    try {
        const batch = writeBatch(db);
        const collectionRef = collection(db, GHOST_POINTS_COLLECTION);

        for (const point of points) {
            const q = query(
                collectionRef,
                where('screen_id', '==', point.screen_id)
            );
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                batch.delete(doc.ref);
            });
        }

        await batch.commit();
    } catch (error) {
        console.error('Error deleting ghost points:', error);
    }
};
const getLastGhostScreenId = async () => {
    const ghostPointsCollectionRef = collection(db, GHOST_POINTS_COLLECTION);

    const q = query(
        ghostPointsCollectionRef,
        orderBy('screen_id', 'desc'),
        limit(1)
    );
    const snapshot = await getDocs(q);
    const screen = snapshot.docs[0].data();
    const screenId = screen.screen_id;

    if (!snapshot.empty) {
        return parseInt(screenId);
    } else {
        return null;
    }
};

const getGhostScreensInTile = async (
    tile: { latMin: number; latMax: number; lonMin: number; lonMax: number },
    screens: GhostScreen[]
): Promise<GhostScreen[]> => {
    try {
        const { latMin, latMax, lonMin, lonMax } = tile;

        const ghostPointsRef = collection(db, GHOST_POINTS_COLLECTION);

        let q = query(
            ghostPointsRef,
            where('location.lat', '>', latMin),
            where('location.lat', '<', latMax),
            where('location.lon', '>', lonMin),
            where('location.lon', '<', lonMax)
        );

        const querySnapshot = await getDocs(q);

        const ghostPoints = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
                id: doc.id,
                ...data
            };
        }) as unknown as GhostScreen[];

        return ghostPoints;
    } catch (error) {
        console.error('Error fetching ghost points in tile:', error);
        return [];
    }
};

export {
    saveGhostScreen,
    saveManyGhostScreens,
    getAllGhostScreens,
    deleteGhostScreen,
    deleteManyGhostScreens,
    getLastGhostScreenId,
    updateGhostScreen,
    getGhostScreensInTile
};
