import React, { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { getIcon } from '../icons/get-icon';

interface Props {
    flyTo: L.LatLngExpression | null;
    setFlyTo: React.Dispatch<React.SetStateAction<L.LatLngExpression | null>>;
    showTemporaryScreenMarker: boolean;
}

export default function FlyToScreen(props: Props) {
    const {
        flyTo,
        setFlyTo,
        showTemporaryScreenMarker: showTemporaryScreenMarker
    } = props;
    const map = useMap();
    const temporaryMarkerRef = useRef<L.Marker | null>(null);

    useEffect(() => {
        if (flyTo) {
            map.flyTo(flyTo, 18, { duration: 3 });

            if (showTemporaryScreenMarker) {
                if (temporaryMarkerRef.current) {
                    temporaryMarkerRef.current.remove();
                }

                const temporaryMarker = L.marker(flyTo, {
                    icon: getIcon('temporary'),
                    title: 'Temporary Marker'
                }).addTo(map);

                temporaryMarkerRef.current = temporaryMarker;
            }
        } else if (temporaryMarkerRef.current) {
            temporaryMarkerRef.current.remove();
            temporaryMarkerRef.current = null;
        }

        return () => {
            if (temporaryMarkerRef.current && showTemporaryScreenMarker) {
                temporaryMarkerRef.current.remove();
            }
        };
    }, [flyTo, map, setFlyTo]);

    return null;
}
