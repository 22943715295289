export function loadScript(
    src: string,
    position: HTMLElement | null,
    id: string
) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

export const GOOGLE_MAPS_API_URL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_FIREBASE_API_KEY}&libraries=places`;


export const geocodeAddress = async (address: string) => {
    try {
        const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
        if (!apiKey) {
            throw new Error(
                'Google Maps API key is not defined in environment variables.'
            );
        }

        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
        )}&key=${apiKey}`;
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === 'OK' && data.results.length > 0) {
            const location = data.results[0].geometry.location;
            return { lat: location.lat, lon: location.lng };
        }
        return null;
    } catch (error) {
        console.error('geocodeAddress error:', error);
        return null;
    }
};