import React, { useEffect, useRef } from 'react';
import { GOOGLE_MAPS_API_URL, loadScript } from '../service/google-places-api';

const GoogleMapsComponent = () => {
    const loaded = useRef(false);

    useEffect(() => {
        if (typeof window !== 'undefined' && !loaded.current) {
            if (!document.querySelector('#google-maps')) {
                loadScript(
                    GOOGLE_MAPS_API_URL,
                    document.querySelector('head'),
                    'google-maps'
                );
            }
            loaded.current = true;
        }
    }, []);

    return <div id="map-container" />;
};

export default GoogleMapsComponent;
