import {
    Drawer,
    Button,
    Box,
    IconButton,
    Typography,
    Slider,
    Input,
    Switch,
    FormControlLabel
} from '@mui/material';
import React, { useState } from 'react';
import { GhostScreen } from '../../types/ghost-screen';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Divider from '@mui/material/Divider';
import { deleteManyGhostScreens } from '../../service/ghost-screen-service';
import AddScreenToAddress from './add-screen-to-address';
import GroupsDialog from './groups/groups-dialog';
import GropsSection from './groups/groups-section';
import { useGroups } from '../../contexts/groupsContext';
import Notes from './notes';

interface Props {
    open: boolean;
    toggleDrawer: (open: boolean) => () => void;
    screens: GhostScreen[];
    setScreens: React.Dispatch<React.SetStateAction<GhostScreen[]>>;
    isAddingPoint: boolean;
    setIsAddingPoint: React.Dispatch<React.SetStateAction<boolean>>;
    isAddingPointCircle: boolean;
    setIsAddingPointCircle: React.Dispatch<React.SetStateAction<boolean>>;
    numberOfPoints: number;
    setNumberOfPoints: React.Dispatch<React.SetStateAction<number>>;
    isAddingPointCustomArea: boolean;
    setIsAddingPointCustomArea: React.Dispatch<React.SetStateAction<boolean>>;
    isMarkingPoints: boolean;
    setIsMarkingPoints: React.Dispatch<React.SetStateAction<boolean>>;
    flyTo: L.LatLngExpression | null;
    setFlyTo: React.Dispatch<React.SetStateAction<L.LatLngExpression | null>>;
    note: string;
    setNote: React.Dispatch<React.SetStateAction<string>>;
    useGoogleMaps: boolean;
    handleGoogleMapSwitch: any;
}

export function AdminDrawer(props: Props) {
    const {
        open,
        toggleDrawer,
        screens,
        setScreens,
        isAddingPoint,
        setIsAddingPoint,
        isAddingPointCircle,
        setIsAddingPointCircle,
        numberOfPoints,
        setNumberOfPoints,
        isAddingPointCustomArea,
        setIsAddingPointCustomArea,
        isMarkingPoints,
        setIsMarkingPoints,
        flyTo,
        setFlyTo,
        note,
        setNote,
        useGoogleMaps,
        handleGoogleMapSwitch
    } = props;

    const { selectedGroupRef } = useGroups();

    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [isGroupsDialogOpen, setIsGroupsDialogOpen] =
        useState<boolean>(false);

    const [isAddingPointAddress, setIsAddingPointAddress] =
        useState<boolean>(false);

    const handleSliderChange = (
        event: React.SyntheticEvent | Event,
        newValue: number | number[]
    ) => {
        setNumberOfPoints(newValue as number);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNumberOfPoints(
            event.target.value === '' ? 0 : Number(event.target.value)
        );
    };

    const markedScreens: GhostScreen[] = screens.filter((s: GhostScreen) => {
        return s.isMarked === true;
    });

    const handleClearMarking = () => {
        const updatedScreens = screens.map((screen) =>
            screen.isMarked ? { ...screen, isMarked: false } : screen
        );
        setScreens(updatedScreens);
    };

    const handledeleteManyGhostScreens = () => {
        setIsDeleting(true);
        deleteManyGhostScreens(markedScreens)
            .then(() => {
                const updatedScreens = screens.filter(
                    (screen) => !screen.isMarked
                );

                setScreens(updatedScreens);
            })
            .catch((error) => {
                console.error('Error deleting ghost points:', error);
            })
            .finally(() => {
                setIsDeleting(false);
            });
    };

    const clearOptions = () => {
        setIsAddingPoint(false);
        setIsAddingPointAddress(false);
        setIsAddingPointCircle(false);
        setIsAddingPointCustomArea(false);
        setIsMarkingPoints(false);
    };

    const handleSelectAllGroupScreens = () => {
        if (!selectedGroupRef) {
            alert('Select group');
            return;
        }

        const updatedScreens = screens.map((s) => {
            if (s.group?.id === selectedGroupRef.id) {
                return { ...s, isMarked: true };
            }
            return s;
        });

        setScreens(updatedScreens);
    };

    return (
        <>
            <Drawer
                open={open}
                onClose={() => {
                    toggleDrawer(false);
                    clearOptions();
                }}
                anchor="right"
                variant="persistent"
                sx={{ '& .MuiDrawer-paper': { bgcolor: '#f0f2f5' } }}
            >
                <Box
                    sx={{
                        width: 350,
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    role="presentation"
                >
                    <Box sx={{ mb: 2, bgcolor: '#f0f2f5' }}>
                        <IconButton
                            onClick={
                                toggleDrawer(false)
                                // clearOptions();
                            }
                            size="large"
                            sx={{ width: '50px' }}
                        >
                            <KeyboardDoubleArrowRightIcon />
                        </IconButton>
                        <Divider variant="fullWidth" />
                    </Box>
                    <Box sx={{ m: 2, mt: 0 }}>
                        <GropsSection
                            isOpen={isGroupsDialogOpen}
                            setIsOpen={setIsGroupsDialogOpen}
                            drawerOpen={open}
                        />
                        <Divider variant="middle" sx={{ m: 1 }} />
                        <Typography variant="h5">Add single screen</Typography>
                        {/* <Divider variant="middle" sx={{ m: 1 }} /> */}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mb: 1,
                                mt: 1
                            }}
                        >
                            <Button
                                variant="contained"
                                color={!isAddingPoint ? 'primary' : 'success'}
                                sx={{ mb: 2, width: '48%' }}
                                onClick={() => {
                                    clearOptions();
                                    setIsAddingPoint(!isAddingPoint);
                                }}
                            >
                                On click
                            </Button>
                            <Button
                                variant="contained"
                                color={
                                    !isAddingPointAddress
                                        ? 'primary'
                                        : 'success'
                                }
                                sx={{ mb: 2, width: '48%' }}
                                onClick={() => {
                                    clearOptions();
                                    setIsAddingPointAddress(
                                        !isAddingPointAddress
                                    );
                                    setFlyTo(null);
                                }}
                            >
                                To address
                            </Button>
                        </Box>
                        {isAddingPointAddress && (
                            <AddScreenToAddress
                                screens={screens}
                                setScreens={setScreens}
                                flyTo={flyTo}
                                setFlyTo={setFlyTo}
                                note={note}
                            />
                        )}
                        <Divider variant="middle" sx={{ m: 1 }} />
                        <Typography variant="h5">Add screens</Typography>
                        <Typography variant="caption">
                            Number of points to generate
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                mb: 2,
                                mt: 1
                            }}
                        >
                            <Slider
                                defaultValue={numberOfPoints}
                                aria-label="Default"
                                valueLabelDisplay="auto"
                                onChangeCommitted={handleSliderChange}
                                sx={{ width: '75%' }}
                            />
                            <Input
                                value={numberOfPoints}
                                size="small"
                                onChange={handleInputChange}
                                sx={{ ml: 2 }}
                                inputProps={{
                                    step: 10,
                                    min: 0,
                                    max: 100,
                                    type: 'number',
                                    'aria-labelledby': 'input-slider'
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                mt: 1
                            }}
                        >
                            <Button
                                variant="contained"
                                color={
                                    !isAddingPointCircle ? 'primary' : 'success'
                                }
                                sx={{ mb: 2, width: '48%' }}
                                onClick={() => {
                                    clearOptions();
                                    setIsAddingPointCircle(
                                        !isAddingPointCircle
                                    );
                                }}
                            >
                                Circle Area
                            </Button>
                            <Button
                                variant="contained"
                                color={
                                    !isAddingPointCustomArea
                                        ? 'primary'
                                        : 'success'
                                }
                                sx={{ mb: 2, width: '48%' }}
                                onClick={() => {
                                    clearOptions();
                                    setIsAddingPointCustomArea(
                                        !isAddingPointCustomArea
                                    );
                                }}
                            >
                                Custom Area
                            </Button>
                        </Box>
                        <Divider variant="middle" sx={{ m: 1 }} />
                        <Typography variant="h5">Edit screens</Typography>
                        <Button
                            variant="contained"
                            sx={{ mb: 2, width: '100%' }}
                            onClick={handleSelectAllGroupScreens}
                        >
                            Select all group screens
                        </Button>
                        <Button
                            variant="contained"
                            color={!isMarkingPoints ? 'primary' : 'success'}
                            sx={{ mb: 2, width: '100%' }}
                            onClick={() => {
                                clearOptions();
                                setIsMarkingPoints(!isMarkingPoints);
                            }}
                        >
                            select screens for editing
                        </Button>
                        {markedScreens && markedScreens.length > 0 && (
                            <>
                                {' '}
                                <Typography variant="caption">
                                    Number of selected screens{' '}
                                    {markedScreens.length}
                                </Typography>
                                <br />
                                {markedScreens.map((point) => (
                                    <Typography variant="caption">
                                        {`${point.type}-${point.screen_id}`}
                                        {', '}
                                    </Typography>
                                ))}
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        mb: 2
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        sx={{ mb: 2, width: '48%' }}
                                        onClick={handleClearMarking}
                                    >
                                        clear
                                    </Button>

                                    <Button
                                        variant="contained"
                                        sx={{
                                            mb: 2,
                                            width: '48%',
                                            fontSize: '12px'
                                        }}
                                        onClick={handledeleteManyGhostScreens}
                                        color={
                                            !isDeleting ? 'primary' : 'success'
                                        }
                                    >
                                        {!isDeleting
                                            ? 'delete screens'
                                            : 'deleting...'}
                                    </Button>
                                </Box>
                            </>
                        )}
                        <Notes note={note} setNote={setNote} />
                        <Divider variant="middle" sx={{ m: 1 }} />

                        <FormControlLabel
                            control={
                                <Switch
                                    checked={useGoogleMaps}
                                    onChange={handleGoogleMapSwitch}
                                    color="primary"
                                />
                            }
                            label={
                                <Typography variant="body1">
                                    {useGoogleMaps
                                        ? 'Google Maps'
                                        : 'OpenStreetMap'}
                                </Typography>
                            }
                        />
                    </Box>
                </Box>
            </Drawer>
            <GroupsDialog
                isOpen={isGroupsDialogOpen}
                setIsOpen={setIsGroupsDialogOpen}
            />
        </>
    );
}
