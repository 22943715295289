import React, { useState, useEffect } from 'react';
import { useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import { GhostScreen } from '../types/ghost-screen';
import {
    getLastGhostScreenId,
    saveManyGhostScreens
} from '../service/ghost-screen-service';
import { useAuth } from '../contexts/authContext';
import { createGhostScreen } from '../utils/new-ghost-marker';
import { useGroups } from '../contexts/groupsContext';

interface Props {
    screens: GhostScreen[];
    setScreens: React.Dispatch<React.SetStateAction<GhostScreen[]>>;
    isAddingPointCircle: boolean;
    setIsAddingPointCircle: React.Dispatch<React.SetStateAction<boolean>>;
    numberOfPoints: number;
    note: string;
}

export default function AddCirclePoints(props: Props) {
    const {
        screens,
        setScreens,
        isAddingPointCircle,
        setIsAddingPointCircle,
        numberOfPoints,
        note
    } = props;

    const { currentUser } = useAuth();

    const { selectedGroupRef } = useGroups();

    const [startPoint, setStartPoint] = useState<L.LatLng | null>(null);
    const [tempCircle, setTempCircle] = useState<L.Circle | null>(null);
    const [draggingEnabled, setDraggingEnabled] = useState(true);

    const map = useMapEvents({
        mousedown: (e) => {
            if (isAddingPointCircle) {
                setDraggingEnabled(false);
                const { lat, lng } = e.latlng;
                const startLatLng = L.latLng(lat, lng);
                setStartPoint(startLatLng);

                const circle = L.circle(startLatLng, {
                    radius: 1,
                    color: 'blue'
                }).addTo(map);
                setTempCircle(circle);
            }
        },
        mousemove: (e) => {
            if (startPoint && tempCircle) {
                const radius = startPoint.distanceTo(e.latlng);
                tempCircle.setRadius(radius);
            }
        },
        mouseup: async (e) => {
            if (startPoint && isAddingPointCircle) {
                const radius = startPoint.distanceTo(e.latlng);

                const ghostPoints = await generateGhostPoints(
                    startPoint,
                    radius,
                    numberOfPoints
                );
                setScreens([...screens, ...ghostPoints]);

                saveManyGhostScreens(ghostPoints);

                setIsAddingPointCircle(false);
                setDraggingEnabled(true);

                setStartPoint(null);
                if (tempCircle) {
                    tempCircle.remove();
                    setTempCircle(null);
                }
            }
        }
    });

    useEffect(() => {
        map.dragging[draggingEnabled ? 'enable' : 'disable']();
    }, [draggingEnabled, map]);

    const generateGhostPoints = async (
        center: L.LatLng,
        radius: number,
        count: number
    ): Promise<GhostScreen[]> => {
        const points: GhostScreen[] = [];
        const idNumber: number | null = await getLastGhostScreenId();

        if (!selectedGroupRef) {
            alert('Select group!');
        }

        if (idNumber && selectedGroupRef) {
            for (let i = 0; i < count; i++) {
                const angle = Math.random() * 2 * Math.PI;
                const distance = Math.random() * radius;

                const offsetX = distance * Math.cos(angle);
                const offsetY = distance * Math.sin(angle);

                const lat = center.lat + offsetY / 111320;
                const lon =
                    center.lng +
                    offsetX / (111320 * Math.cos(center.lat * (Math.PI / 180)));

                const newScreen: GhostScreen = createGhostScreen(
                    lat,
                    lon,
                    idNumber + i + 1,
                    selectedGroupRef,
                    currentUser.email,
                    note
                );

                points.push(newScreen);
            }
        } else if (selectedGroupRef) {
            alert('Firestore error.');
        }

        return points;
    };

    return null;
}
