import { useMap } from 'react-leaflet';
import { useEffect } from 'react';
import {
    getCountryData,
    getUserCountryCode
} from '../service/user-location-service';
import { MapLocation } from '../types/location';
import Cookies from 'js-cookie';

export default function UserLocationCenter() {
    const map = useMap();

    const mapStateCookie = Cookies.get('mapState');

    useEffect(() => {
        map.locate().on('locationfound', async (e) => {
            if (!mapStateCookie) {
                const { lat, lng } = e.latlng;

                try {
                    const countryCode: string = await getUserCountryCode(
                        lat,
                        lng
                    );

                    if (countryCode) {
                        const countryData: MapLocation[] = await getCountryData(
                            countryCode
                        );
                        if (countryData.length > 0) {
                            const countryCenter: any = [
                                parseFloat(countryData[0].lat),
                                parseFloat(countryData[0].lon)
                            ];

                            map.flyTo(countryCenter, 5, { duration: 1 });
                            return;
                        }
                    }

                    map.flyTo(e.latlng, 5, { duration: 1 });
                } catch (error) {
                    console.error('Error with Nominatim API:', error);
                    map.flyTo(e.latlng, 5, { duration: 1 });
                }
            }
        });
    }, []);

    return null;
}
