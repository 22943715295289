import { createLocationIcon } from './location-icon';

const getIconColor = (status: string) => {
    if (status === 'Available') return 'green';
    if (status === 'occupied') return 'red';
    if (status === 'on-demand') return 'yellow';
    if (status === 'Busy') return 'grey';
    return 'blue';
};
export const getIcon = (
    status: string,
    isMarked?: boolean,
    ghostColor?: string
) => {
    if (ghostColor) {
        return createLocationIcon(ghostColor, 'black', isMarked);
    }

    const markerColor = getIconColor(status);

    switch (markerColor) {
        case 'green':
            return createLocationIcon('#00c217', 'black');
        case 'grey':
            return createLocationIcon('#00c217', 'black', isMarked);
        case 'red':
            return createLocationIcon('red', 'black');
        case 'yellow':
            return createLocationIcon('yellow', 'black');
        case 'blue':
            return createLocationIcon('blue', 'black');
        default:
            return createLocationIcon('blue', 'black');
    }
};
