import { TextField, Typography } from '@mui/material';
import { VcpScreen } from '../../types/vcp-screen';
import { renderTextWithLinks } from './render-text-with-links';

interface Props {
    label: string;
    value: any;
    field: keyof VcpScreen;
    subField?: string;
    isEditing: boolean;
    handleInputChange: (
        field: keyof VcpScreen,
        value: any,
        subField?: string
    ) => void;
}

export default function EditableField(props: Props) {
    const { label, value, field, subField, isEditing, handleInputChange } =
        props;

    return isEditing ? (
        <TextField
            fullWidth
            label={label}
            value={value || ''}
            color="primary"
            sx={{ mb: 1 }}
            onChange={(e) => handleInputChange(field, e.target.value, subField)}
        />
    ) : (
        <Typography variant="body1">
            <strong>{label}:</strong>{' '}
            {value ? renderTextWithLinks(value.toString()) : 'N/A'}
        </Typography>
    );
}
