import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Paper } from '@mui/material';
import {
    doCreateUserWithEmailAndPassword,
    doSignInWithEmailAndPassword
} from '../firebase/auth';
import { useAuth } from '../contexts/authContext';
import { Navigate } from 'react-router-dom';

function LoginPage() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { userLoggedIn } = useAuth();
    const [isSigningIn, setIsSigningIn] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleLogin = async () => {
        if (!isSigningIn) {
            setIsSigningIn(true);
            await doSignInWithEmailAndPassword(email, password).catch((err) => {
                setErrorMessage(
                    err.message || 'An error occurred during sign-in.'
                );
                setIsSigningIn(false);
            });
        }
    };

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="90vh"
        >
            {userLoggedIn && <Navigate to={'/'} replace={true} />}
            <Paper elevation={3} sx={{ p: 4, width: 350, textAlign: 'center' }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Login
                </Typography>
                <Box component="form" noValidate autoComplete="off">
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type="password"
                        fullWidth
                        margin="normal"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                        onClick={handleLogin}
                    >
                        Log In
                    </Button>
                </Box>
                {errorMessage && (
                    <Box
                        sx={{
                            bgcolor: 'rgba(255, 0, 0, 0.3)',
                            borderRadius: 1,
                            mt: 1
                        }}
                    >
                        <Typography variant="button" sx={{ color: 'red' }}>
                            {errorMessage}
                        </Typography>
                    </Box>
                )}
            </Paper>
        </Box>
    );
}

export default LoginPage;
