import {
    Divider,
    IconButton,
    InputAdornment,
    TextField,
    Typography
} from '@mui/material';
import React from 'react';
import ClearIcon from '@mui/icons-material/Clear';

interface Props {
    note: string;
    setNote: React.Dispatch<React.SetStateAction<string>>;
}

export default function Notes(props: Props) {
    // INFO
    const { note, setNote } = props;

    const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNote(event.target.value);
    };

    const handleClearNote = () => {
        setNote('');
    };

    return (
        <>
            <Divider variant="middle" sx={{ m: 1 }} />

            <Typography variant="h5">Add info</Typography>
            <TextField
                value={note}
                onChange={handleNoteChange}
                fullWidth={true}
                placeholder="Screen resolution, link, etc."
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {note && (
                                <IconButton onClick={handleClearNote}>
                                    <ClearIcon />
                                </IconButton>
                            )}
                        </InputAdornment>
                    )
                }}
            />
        </>
    );
}
