import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { PlaceType } from '../../types/google-maps';
import { useEffect, useMemo } from 'react';

const autocompleteService = { current: null };

interface Props {
    value: PlaceType | null;
    setValue: React.Dispatch<React.SetStateAction<PlaceType | null>>;
    inputValue: string;
    setInputValue: React.Dispatch<React.SetStateAction<string>>;
    options: readonly PlaceType[];
    setOptions: React.Dispatch<React.SetStateAction<readonly PlaceType[]>>;
    setSelectedAddress: React.Dispatch<React.SetStateAction<PlaceType | null>>;
    flyTo?: L.LatLngExpression | null;
    setFlyTo?: React.Dispatch<React.SetStateAction<L.LatLngExpression | null>>;
    defaultValue?: string;
}

const AddresAutocomplete: React.FC<Props> = ({
    value,
    setValue,
    inputValue,
    setInputValue,
    options,
    setOptions,
    setSelectedAddress,
    flyTo,
    setFlyTo,
    defaultValue
}) => {
    const fetch = useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results?: readonly PlaceType[]) => void
                ) => {
                    (autocompleteService.current as any).getPlacePredictions(
                        request,
                        callback
                    );
                },
                400
            ),
        []
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (
                window as any
            ).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }
        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, setOptions]);

    useEffect(() => {
        if (value && value.place_id) {
            const service = new (
                window as any
            ).google.maps.places.PlacesService(document.createElement('div'));

            service.getDetails(
                { placeId: value.place_id },
                (place: any, status: string) => {
                    if (status === 'OK' && place) {
                        const placeWithLocation: PlaceType = {
                            ...value,
                            geometry: {
                                location: {
                                    lat: place.geometry.location.lat(),
                                    lng: place.geometry.location.lng()
                                }
                            }
                        };
                        setSelectedAddress(placeWithLocation);
                        if (
                            placeWithLocation?.geometry?.location?.lat &&
                            placeWithLocation?.geometry?.location?.lng
                        ) {
                            setFlyTo &&
                                setFlyTo([
                                    placeWithLocation?.geometry?.location?.lat,
                                    placeWithLocation?.geometry.location.lng
                                ]);
                        }
                    }
                }
            );
        }
    }, [value, setSelectedAddress]);

    return (
        <Autocomplete
            sx={{ width: '100%' }}
            getOptionLabel={(option) =>
                typeof option === 'string' ? option : option.description
            }
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            noOptionsText="No locations"
            onChange={(event: any, newValue: PlaceType | null) => {
                setOptions(newValue ? [newValue, ...options] : options);

                setValue(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Add a location"
                    fullWidth
                    defaultValue={defaultValue}
                />
            )}
            renderOption={(props, option) => {
                const { key, ...optionProps } = props;
                const matches =
                    option.structured_formatting.main_text_matched_substrings ||
                    [];
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [
                        match.offset,
                        match.offset + match.length
                    ])
                );

                return (
                    <li key={key} {...optionProps}>
                        <Grid container sx={{ alignItems: 'center' }}>
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                                <LocationOnIcon
                                    sx={{ color: 'text.secondary' }}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    width: 'calc(100% - 44px)',
                                    wordWrap: 'break-word'
                                }}
                            >
                                {parts.map((part, index) => (
                                    <Box
                                        key={index}
                                        component="span"
                                        sx={{
                                            fontWeight: part.highlight
                                                ? 'bold'
                                                : 'regular'
                                        }}
                                    >
                                        {part.text}
                                    </Box>
                                ))}
                                <Typography
                                    variant="body2"
                                    sx={{ color: 'text.secondary' }}
                                >
                                    {
                                        option.structured_formatting
                                            .secondary_text
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                    </li>
                );
            }}
        />
    );
};

export default AddresAutocomplete;
