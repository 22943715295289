import { GhostScreen } from '../types/ghost-screen';
import { DocumentReference } from 'firebase/firestore';

export function createGhostScreen(
    lat: number,
    lon: number,
    idNumber: number,
    group: DocumentReference,
    createdBy: string,
    note?: string,
    address?: string
) {
    const newGhostScreen: GhostScreen = {
        location: { lat: lat, lon: lon, address: address ?? 'Unavailable' },
        status: { isBusy: true },
        screen_id: idNumber,
        type: 'gs',
        group: group,
        note: note,
        createdAt: Date.now(),
        updatedAt: Date.now(),
        createdBy: createdBy ?? 'unknown'
    };

    return newGhostScreen;
}
