import { useState, useEffect } from 'react';
import { useMapEvents } from 'react-leaflet';
import L, { LatLng, Polygon } from 'leaflet';

interface PolygonDrawingProps {
    isDrawingEnabled: boolean;
    setIsDrawingEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    onPolygonComplete: (polygonLayer: Polygon) => void;
}

export function usePolygonDrawing({
    isDrawingEnabled,
    setIsDrawingEnabled,
    onPolygonComplete
}: PolygonDrawingProps) {
    const [points, setPoints] = useState<LatLng[]>([]);
    const [polygonLayer, setPolygonLayer] = useState<Polygon | null>(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [draggingEnabled, setDraggingEnabled] = useState(true);

    const map = useMapEvents({
        mousedown: (e) => {
            if (isDrawingEnabled) {
                setDraggingEnabled(false);
                setIsDrawing(true);
                setPoints([L.latLng(e.latlng.lat, e.latlng.lng)]);
            }
        },
        mousemove: (e) => {
            if (isDrawing) {
                const newPoint = e.latlng;
                setPoints((prevPoints) => {
                    const newPoints = [...prevPoints, newPoint];

                    if (polygonLayer) {
                        polygonLayer.setLatLngs(newPoints);
                    } else {
                        const newPolygon = L.polygon(newPoints, {
                            color: 'blue',
                            fillOpacity: 0.1
                        }).addTo(map);
                        setPolygonLayer(newPolygon);
                    }

                    return newPoints;
                });
            }
        },
        mouseup: () => {
            if (isDrawing && polygonLayer) {
                setIsDrawing(false);
                setIsDrawingEnabled(false);
                onPolygonComplete(polygonLayer);
                setPoints([]);
                if (polygonLayer) {
                    polygonLayer.remove();
                    setPolygonLayer(null);
                }
                setDraggingEnabled(true);
            }
        }
    });

    useEffect(() => {
        map.dragging[draggingEnabled ? 'enable' : 'disable']();
    }, [draggingEnabled, map]);
}
