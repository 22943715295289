import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MapPage from './pages/map-page';
import Navbar from './components/navbar';
import LoginPage from './pages/login-page';
import { AuthProvider } from './contexts/authContext';
import { GroupsProvider } from './contexts/groupsContext';
import { MapStateProvider } from './contexts/mapStateContext';
import AboutPage from './pages/about-page';

function App() {
    return (
        <AuthProvider>
            <MapStateProvider>
                <GroupsProvider>
                    <Router>
                        <div className="App">
                            <Navbar />
                            <Routes>
                                <Route path="/" element={<MapPage />} />
                                <Route path="/about" element={<AboutPage />} />
                                <Route path="/login" element={<LoginPage />} />
                            </Routes>
                        </div>
                    </Router>
                </GroupsProvider>
            </MapStateProvider>
        </AuthProvider>
    );
}

export default App;
