import { Button } from '@mui/material';
import GroupSelect from './group-select';
import { useGroups } from '../../../contexts/groupsContext';

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    drawerOpen: boolean;
}

export default function GropsSection(props: Props) {
    const { isOpen, setIsOpen, drawerOpen } = props;

    const { selectedGroup } = useGroups();

    return (
        <>
            <Button
                variant="contained"
                color={!isOpen ? 'primary' : 'success'}
                sx={{ mb: 2, width: '100%' }}
                onClick={() => setIsOpen(true)}
            >
                {selectedGroup ? 'Update group' : 'Add new group'}
            </Button>
            <GroupSelect groupData={null} setGroupData={null} />
        </>
    );
}
