import React from 'react';
import { useMapEvents } from 'react-leaflet';
import { useAuth } from '../contexts/authContext';
import {
    getLastGhostScreenId,
    saveGhostScreen
} from '../service/ghost-screen-service';
import { createGhostScreen } from '../utils/new-ghost-marker';
import { useGroups } from '../contexts/groupsContext';
import { VcpScreen } from '../types/vcp-screen';

interface Props {
    screens: VcpScreen[];
    setScreens: React.Dispatch<React.SetStateAction<VcpScreen[]>>;
    isAddingPoint: boolean;
    setIsAddingPoint: React.Dispatch<React.SetStateAction<boolean>>;
    note: string;
}

export default function AddOnePoint(props: Props) {
    const { screens, setScreens, isAddingPoint, setIsAddingPoint, note } =
        props;

    const { selectedGroupRef } = useGroups();

    let idNumberIncr = 1;

    const { currentUser } = useAuth();

    const map = useMapEvents({
        click: async (e) => {
            if (isAddingPoint) {
                const { lat, lng } = e.latlng;

                const idNumber: number | null = await getLastGhostScreenId();
                if (!selectedGroupRef) {
                    alert('Select group!');
                }

                if (idNumber && selectedGroupRef) {
                    const newScreen: VcpScreen = createGhostScreen(
                        lat,
                        lng,
                        idNumber + idNumberIncr,
                        selectedGroupRef,
                        currentUser.email,
                        note
                    );

                    const newScreens = [newScreen, ...screens];
                    setScreens(newScreens);
                    saveGhostScreen(newScreen);
                    idNumberIncr++;
                } else {
                    alert('Firestore error.');
                }

                // setIsAddingPoint(false);
            }
        }
    });
    return null;
}
