import { useMapEvents } from 'react-leaflet';
import { useMapState } from '../contexts/mapStateContext';

export default function SaveMapStateToCookies() {
    const { setNewMapState } = useMapState();
    useMapEvents({
        moveend: (e) => {
            const map = e.target;
            const center = map.getCenter();
            const zoom = map.getZoom();

            setNewMapState(center, zoom);
        },
        zoomend: (e) => {
            const map = e.target;
            const center = map.getCenter();
            const zoom = map.getZoom();

            setNewMapState(center, zoom);
        }
    });

    return null;
}
