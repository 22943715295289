import { db } from '../firebase/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { HTML_CONTENT_COLLECTION } from '../utils/collection-names';

const fetchHtmlContent = async (documentId: string): Promise<string> => {
    try {
        const docRef = doc(db, HTML_CONTENT_COLLECTION, documentId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const rawHtml = docSnap.data().html;
            return rawHtml;
        } else {
            console.error('Document not found.');
            return '';
        }
    } catch (error) {
        console.error('Error fetching HTML content:', error);
        throw error;
    }
};
const editHtmlContent = async (
    documentId: string,
    newHtml: string
): Promise<void> => {
    try {
        const docRef = doc(db, HTML_CONTENT_COLLECTION, documentId);
        await setDoc(docRef, { html: newHtml }, { merge: true });
        console.log('HTML content updated successfully.');
    } catch (error) {
        console.error('Error editing HTML content:', error);
        throw error;
    }
};

export { fetchHtmlContent, editHtmlContent };
