import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/authContext';
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import MonacoEditor from '@monaco-editor/react';
import { fetchHtmlContent, editHtmlContent } from '../service/about-service';

export default function AboutPage() {
    const { userLoggedIn } = useAuth();

    const [htmlContent, setHtmlContent] = useState<string>('');
    const [proxyHtmlContent, setProxyHtmlContent] = useState<string>('');
    const [editContent, setEditContent] = useState<string>('');
    const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);

    const addProxyToIframe = (html: string): string => {
        const proxyUrl = process.env.REACT_APP_PROXY_OTHER_URL;
        const canvaUrlPattern = /https:\/\/www.canva.com\/design\/[^"]+/;

        return html.replace(
            /(<iframe[^>]+src=")(https:\/\/[^"]+)(")/g,
            (_, prefix, src, suffix) => {
                if (canvaUrlPattern.test(src)) {
                    const proxiedSrc = `${proxyUrl}${src}`;
                    return `${prefix}${proxiedSrc}${suffix}`;
                }
                return `${prefix}${src}${suffix}`;
            }
        );
    };

    useEffect(() => {
        const loadHtmlContent = async () => {
            try {
                const content = await fetchHtmlContent('aboutPageHtml');
                const contentWithProxy = addProxyToIframe(content);
                setHtmlContent(content);
                setProxyHtmlContent(contentWithProxy);
            } catch (error) {
                console.error('Error loading HTML content:', error);
            }
        };

        loadHtmlContent();
    }, []);

    const handleEditClick = () => {
        setEditContent(htmlContent);
        setIsEditModalOpen(true);
    };

    const handleSaveClick = async () => {
        try {
            await editHtmlContent('aboutPageHtml', editContent);
            setHtmlContent(editContent);
            const proxyEditedContent = addProxyToIframe(editContent);
            setProxyHtmlContent(proxyEditedContent);
            setIsEditModalOpen(false);
        } catch (error) {
            console.error('Error saving HTML content:', error);
        }
    };

    return (
        <Box>
            {/* Embedded HTML */}
            <Box
                sx={{ mt: 2 }}
                dangerouslySetInnerHTML={{ __html: proxyHtmlContent }}
            />

            {/* Edit Modal */}
            {userLoggedIn && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2
                    }}
                >
                    <Box sx={{ margin: 'auto', mt: 1 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ mr: 2 }}
                            onClick={handleEditClick}
                        >
                            Edit
                        </Button>
                    </Box>
                </Box>
            )}
            <Dialog
                open={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                maxWidth="xl"
                fullWidth
            >
                <DialogTitle>Edit HTML Content</DialogTitle>
                <DialogContent>
                    <MonacoEditor
                        height="600px"
                        language="html"
                        theme="vs-dark"
                        value={editContent}
                        onChange={(value) => setEditContent(value || '')}
                        options={{
                            minimap: { enabled: false }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setIsEditModalOpen(false)}
                        color="secondary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSaveClick}
                        color="primary"
                        variant="contained"
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
