import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useMapState } from '../contexts/mapStateContext';

export default function TrackMapView() {
    const map = useMap();

    const { setBounds } = useMapState();

    const updateBounds = () => {
        const currentBounds = map.getBounds();
        setBounds(currentBounds);
    };

    useEffect(() => {
        updateBounds();
    }, []);

    useEffect(() => {
        map.on('moveend', updateBounds);
        map.on('zoomend', updateBounds);

        return () => {
            map.off('moveend', updateBounds);
            map.off('zoomend', updateBounds);
        };
    }, [map]);

    return null;
}
