import * as React from 'react';
import AddresAutocomplete from './autocomplete';
import { PlaceType } from '../../types/google-maps';
import { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { GhostScreen } from '../../types/ghost-screen';
import { useAuth } from '../../contexts/authContext';
import {
    getLastGhostScreenId,
    saveGhostScreen
} from '../../service/ghost-screen-service';
import { createGhostScreen } from '../../utils/new-ghost-marker';
import { useGroups } from '../../contexts/groupsContext';
import GoogleMapsComponent from '../../hooks/use-loading-google-maps';

interface Props {
    screens: GhostScreen[];
    setScreens: React.Dispatch<React.SetStateAction<GhostScreen[]>>;
    flyTo: L.LatLngExpression | null;
    setFlyTo: React.Dispatch<React.SetStateAction<L.LatLngExpression | null>>;
    note: string;
}

export default function AddScreenToAddress(props: Props) {
    const { screens, setScreens, flyTo, setFlyTo, note } = props;

    const { selectedGroupRef } = useGroups();

    const { currentUser } = useAuth();

    const [value, setValue] = useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [options, setOptions] = useState<readonly PlaceType[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<PlaceType | null>(
        null
    );

    const handleAddScreen = async () => {
        if (selectedAddress) {
            const idNumber: number | null = await getLastGhostScreenId();
            if (!selectedGroupRef) {
                alert('Select group!');
            }
            if (idNumber && selectedGroupRef) {
                const newScreen: GhostScreen = createGhostScreen(
                    selectedAddress.geometry.location.lat,
                    selectedAddress.geometry.location.lng,
                    idNumber + 1,
                    selectedGroupRef,
                    currentUser.email,
                    note,
                    selectedAddress.description
                );

                const newScreens = [newScreen, ...screens];
                setScreens(newScreens);
                saveGhostScreen(newScreen);
                handleClearAutocompelte();
            } else {
                alert('Firestore error.');
            }
        }
    };

    const handleClearAutocompelte = () => {
        setFlyTo(null);
        setValue(null);
        setSelectedAddress(null);
    };

    return (
        <div>
            {' '}
            <GoogleMapsComponent />
            <AddresAutocomplete
                value={value}
                setValue={setValue}
                inputValue={inputValue}
                setInputValue={setInputValue}
                options={options}
                setOptions={setOptions}
                setSelectedAddress={setSelectedAddress}
                flyTo={flyTo}
                setFlyTo={setFlyTo}
            />
            {selectedAddress && (
                <Box>
                    <Typography variant="h6">Selected Address:</Typography>
                    <Typography variant="body1">
                        {selectedAddress.description} lat:
                        {selectedAddress.geometry.location.lat ?? ''} lon:
                        {selectedAddress.geometry.location.lng ?? ''}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mb: 2
                        }}
                    >
                        <Button
                            variant="contained"
                            color={'primary'}
                            sx={{ width: '48%' }}
                            onClick={handleAddScreen}
                        >
                            Save screen
                        </Button>
                        <Button
                            variant="contained"
                            color={'primary'}
                            sx={{ width: '48%' }}
                            onClick={handleClearAutocompelte}
                        >
                            Clear
                        </Button>
                    </Box>
                </Box>
            )}
        </div>
    );
}
