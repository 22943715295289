import { LatLng } from 'leaflet';

export function isPointInPolygon(
    point: LatLng,
    polygonPoints: LatLng[]
): boolean {
    let inside = false;
    for (
        let i = 0, j = polygonPoints.length - 1;
        i < polygonPoints.length;
        j = i++
    ) {
        const xi = polygonPoints[i].lat,
            yi = polygonPoints[i].lng;
        const xj = polygonPoints[j].lat,
            yj = polygonPoints[j].lng;
        const intersect =
            yi > point.lng !== yj > point.lng &&
            point.lat < ((xj - xi) * (point.lng - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
    }
    return inside;
}
