import React from 'react';
import { GhostScreen } from '../types/ghost-screen';
import { usePolygonDrawing } from '../hooks/use-polygon-drawing';
import L, { LatLng, Polygon } from 'leaflet';
import { isPointInPolygon } from '../utils/is-point-in-pollygon';

interface Props {
    screens: GhostScreen[];
    setScreens: React.Dispatch<React.SetStateAction<GhostScreen[]>>;
    isMarkingPoints: boolean;
    setIsMarkingPoints: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MarkCustomAreaPoints(props: Props) {
    const { screens, setScreens, isMarkingPoints, setIsMarkingPoints } = props;

    const markGhostPoints = (polygonLayer: Polygon) => {
        if (!polygonLayer) return;

        const polygonLatLngs = polygonLayer.getLatLngs()[0] as LatLng[];
        const bounds = polygonLayer.getBounds();

        const updatedScreens = screens.map((screen) => {
            const point = L.latLng(
                screen?.location?.lat || 0,
                screen?.location?.lon || 0
            );
            if (
                screen?.status?.isBusy &&
                bounds.contains(point) &&
                isPointInPolygon(point, polygonLatLngs)
            ) {
                return { ...screen, isMarked: true };
            }
            return screen;
        });

        setScreens(updatedScreens);
    };

    usePolygonDrawing({
        isDrawingEnabled: isMarkingPoints,
        setIsDrawingEnabled: setIsMarkingPoints,
        onPolygonComplete: (polygonLayer) => {
            markGhostPoints(polygonLayer);
        }
    });

    return null;
}
