import React, { useContext, useState, useEffect } from 'react';
import {
    getAllGroups,
    getGroup,
    getGroupRef
} from '../../service/groups-service';
import { setRef } from '@mui/material';

const GroupsContext = React.createContext();

export function useGroups() {
    return useContext(GroupsContext);
}

export function GroupsProvider({ children }) {
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [selectedGroupRef, setSelectedGroupRef] = useState(null);
    const [groups, setGroups] = useState([]);

    const getGroups = async () => {
        const newGroups = await getAllGroups();
        setGroups(newGroups);
    };

    useEffect(() => {
        getGroups();
    }, []);    

    const getOneGroup = async (groupId) => {
        const newGroup = await getGroup(groupId);
        setSelectedGroup(newGroup);
    };

    useEffect(() => {
        const getRef = async () => {
            const ref = await getGroupRef(selectedGroup.id);
            setSelectedGroupRef(ref);
        };
        if (selectedGroup) {
            getRef();
        } else {
            setRef(null);
        }
    }, [selectedGroup]);

    const value = {
        selectedGroup,
        groups,
        getGroups,
        getOneGroup,
        setSelectedGroup,
        selectedGroupRef,
        setGroups
    };

    return (
        <GroupsContext.Provider value={value}>
            {children}
        </GroupsContext.Provider>
    );
}
