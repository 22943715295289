import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import { getIcon } from '../../icons/get-icon';
import { Group } from '../../types/group';
import { getArea } from '../../service/area-service';
import { useGroups } from '../../contexts/groupsContext';
import { VcpScreen } from '../../types/vcp-screen';
import MarkerPopup from './popup';
import EditMarkerDialog from './edit-marker-dialog';

interface Props {
    screen: VcpScreen;
    screens: VcpScreen[];
    setScreens: React.Dispatch<React.SetStateAction<VcpScreen[]>>;
}

export default function VcpMarker(props: Props) {
    const { screen, screens, setScreens } = props;
    const [isAreaVisible, setIsAreaVisible] = useState<boolean>(false);
    const [polygonLayer, setPolygonLayer] = useState<L.Polygon | null>(null);
    const [groupData, setGroupData] = useState<Group | null>(null);
    const [areaChecked, setAreaChecked] = useState<boolean>(false);

    const [editMarkerDialogOpen, setEditMarkerDialogOpen] =
        useState<boolean>(false);

    let popupRef: any = useRef();

    const map = useMap();
    const { groups } = useGroups();

    useEffect(() => {
        if (screen.group?.id && groupData === null) {
            const getGroupData = groups.filter(
                (g: Group) => g.id === screen.group?.id
            );
            setGroupData(getGroupData[0]);
        }
    }, [screen.group?.id, groups, groupData]);

    const status = useMemo(() => {
        if (screen?.status?.isBusy) return 'Busy';

        if (!screen?.status?.isOnline) return 'occupied';

        if (screen?.status?.isOnline) {
            const lastActiveTimestamp = Number(screen.status.lastActive);
            const lastActiveDate = new Date(lastActiveTimestamp);
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

            if (lastActiveDate >= sevenDaysAgo) {
                return 'Available';
            }
        }

        return 'on-demand';
    }, [screen.status]);

    const position: any = useMemo(() => {
        return screen?.location?.lat && screen?.location?.lon
            ? [screen.location.lat, screen.location.lon]
            : null;
    }, [screen]);

    useMapEvents({
        click: () => {
            if (isAreaVisible && polygonLayer) {
                map.removeLayer(polygonLayer);
                setPolygonLayer(null);
                setIsAreaVisible(false);
            }
        }
    });

    const handleMarkerClick = async () => {
        if (screen?.location?.lat && screen?.location?.lon && !areaChecked) {
            const area = await getArea(
                screen?.location?.lat,
                screen?.location?.lon
            );
            screen.location.area = area;
            setAreaChecked(true);

            popupRef && popupRef.openOn(map);
        }

        if (!isAreaVisible && screen?.location?.area) {
            const polygon = L.polygon(screen?.location?.area, {
                color: 'blue'
            }).addTo(map);
            setPolygonLayer(polygon);
            setIsAreaVisible(true);
        } else if (polygonLayer) {
            map.removeLayer(polygonLayer);
            setPolygonLayer(null);
            setIsAreaVisible(false);
        }
    };

    if (position) {
        return (
            <>
                <Marker
                    key={`${screen.type ?? screen?.deviceInfo?.type}-${
                        screen.screen_id ?? screen.id
                    }`}
                    position={position}
                    title={`${screen.type ?? screen?.deviceInfo?.type}-${
                        screen.screen_id ?? screen.id
                    }`}
                    icon={getIcon(status, screen.isMarked, groupData?.color)}
                    eventHandlers={{ click: handleMarkerClick }}
                >
                    <Popup
                        ref={(r) => {
                            popupRef = r;
                        }}
                    >
                        <MarkerPopup
                            screen={screen}
                            setScreens={setScreens}
                            groupData={groupData}
                            screens={screens}
                            status={status}
                            isOpen={editMarkerDialogOpen}
                            setIsOpen={setEditMarkerDialogOpen}
                        />
                    </Popup>
                </Marker>
                <EditMarkerDialog
                    isOpen={editMarkerDialogOpen}
                    setIsOpen={setEditMarkerDialogOpen}
                    screen={screen}
                    groupData={groupData}
                    setGroupData={setGroupData}
                    setScreens={setScreens}
                />
            </>
        );
    }
    return null;
}
