import { db } from '../firebase/firebase';
import {
    collection,
    addDoc,
    doc,
    getDocs,
    updateDoc,
    getDoc
} from 'firebase/firestore';
import { Group } from '../types/group';
import { GROUPS_COLLECTION } from '../utils/collection-names';

const addGroup = async (groupData: Group) => {
    try {
        const docRef = await addDoc(
            collection(db, GROUPS_COLLECTION),
            groupData
        );
        return docRef;
    } catch (error) {
        console.error('Error adding group:', error);
    }
};

const updateGroup = async (groupId: string, groupData: Partial<Group>) => {
    try {
        const docRef = doc(db, GROUPS_COLLECTION, groupId);
        await updateDoc(docRef, groupData);
    } catch (error) {
        console.error('Error updating group:', error);
    }
};

const getGroupRef = async (groupId: string) => {
    const docRef = doc(db, GROUPS_COLLECTION, groupId);
    return docRef;
};

const getGroup = async (groupId: string) => {
    try {
        const docRef = doc(db, GROUPS_COLLECTION, groupId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const groupData = docSnap.data();
            return groupData as unknown as Group;
        } else {
            console.log('No such document!');
            return null;
        }
    } catch (error) {
        console.error('Error fetching group:', error);
        return null;
    }
};

const getAllGroups = async (): Promise<Group[]> => {
    try {
        const querySnapshot = await getDocs(collection(db, GROUPS_COLLECTION));

        const groups: Group[] = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...(doc.data() as Group)
        }));

        return groups;
    } catch (error) {
        console.error('Error fetching groups:', error);
        return [];
    }
};
export { addGroup, updateGroup, getGroupRef, getGroup, getAllGroups };
