import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputLabel,
    OutlinedInput,
    TextField
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Group } from '../../../types/group';
import { useAuth } from '../../../contexts/authContext';
import { addGroup, updateGroup } from '../../../service/groups-service';
import Github, { GithubPlacement } from '@uiw/react-color-github';
import { useGroups } from '../../../contexts/groupsContext';

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function GroupsDialog(props: Props) {
    const { isOpen, setIsOpen } = props;
    const { currentUser } = useAuth();

    const { selectedGroup, groups, setGroups, setSelectedGroup } = useGroups();

    const [color, setColor] = useState<string>(selectedGroup?.color ?? '#fff');
    const [note, setNote] = useState<string>(selectedGroup?.note ?? '');
    const [groupName, setGroupName] = useState<string>(
        selectedGroup?.name ?? ''
    );

    useEffect(() => {
        if (selectedGroup) {
            setColor(selectedGroup.color ?? '#fff');
            setNote(selectedGroup.note ?? '');
            setGroupName(selectedGroup.name ?? '');
        } else {
            setColor('#fff');
            setNote('');
            setGroupName('');
        }
    }, [selectedGroup]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGroupName(event.target.value);
    };

    const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNote(event.target.value);
    };

    const handleClose = () => {
        setGroupName('');
        setNote('');
        setColor('#fff');
        setIsOpen(false);
    };

    const handleSave = async () => {
        if (groupName !== '') {
            const groupData: Group = {
                name: groupName,
                color: color,
                note: note,
                createdAt: selectedGroup?.createdAt ?? Date.now(),
                updatedAt: Date.now(),
                createdBy: currentUser.email ?? ''
            };

            try {
                if (selectedGroup && selectedGroup.id) {
                    await updateGroup(selectedGroup.id, groupData);
                } else {
                    const docRef = await addGroup(groupData);
                    groupData.id = docRef?.id;
                    setGroups([groupData, ...groups]);
                    setSelectedGroup(groupData);
                }
                handleClose();
            } catch (error) {
                console.error('Error saving group:', error);
            }
        } else {
            alert('Please add a group name');
        }
    };

    return (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
            <Box sx={{ mb: 2, bgcolor: '#f0f2f5' }}>
                <IconButton
                    onClick={handleClose}
                    size="large"
                    sx={{ width: '50px', float: 'right' }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogTitle>
                {selectedGroup ? 'Edit Group' : 'Add New Group'}
            </DialogTitle>
            <DialogContent>
                <InputLabel>Group name</InputLabel>
                <OutlinedInput value={groupName} onChange={handleChange} />
                <InputLabel sx={{ mt: 1 }}>Marker color</InputLabel>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Github
                        color={color}
                        placement={GithubPlacement.Top}
                        onChange={(newColor) => setColor(newColor.hex)}
                    />
                </Box>

                <InputLabel sx={{ mt: 1 }}>Add note</InputLabel>
                <TextField value={note} onChange={handleNoteChange} />
            </DialogContent>
            <Button variant="contained" sx={{ m: 2 }} onClick={handleSave}>
                {selectedGroup ? 'Update Group' : 'Save Group'}
            </Button>
        </Dialog>
    );
}
