import React, { useEffect, useState } from 'react';
import {
    Box,
    Dialog,
    DialogTitle,
    IconButton,
    Typography,
    Divider,
    Grid,
    Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { VcpScreen } from '../../types/vcp-screen';
import { Group } from '../../types/group';
import EditableField from './editable-field';
import { updateGhostScreen } from '../../service/ghost-screen-service';
import GroupSelect from '../admin-panel/groups/group-select';
import { getGroupRef } from '../../service/groups-service';
import { PlaceType } from '../../types/google-maps';
import AddresAutocomplete from '../admin-panel/autocomplete';
import GoogleMapsComponent from '../../hooks/use-loading-google-maps';

interface Props {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    screen: VcpScreen;
    groupData: Group | null;
    setGroupData: React.Dispatch<React.SetStateAction<Group | null>>;
    setScreens: React.Dispatch<React.SetStateAction<VcpScreen[]>>;
}

export default function EditMarkerDialog(props: Props) {
    const { isOpen, setIsOpen, screen, groupData, setGroupData, setScreens } =
        props;

    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editableScreen, setEditableScreen] = useState<VcpScreen>(screen);
    const [oldGroupData, setOldGroupData] = useState<Group | null>(null);

    const [valueAddress, setValueAddress] = useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = useState<string>('');
    const [options, setOptions] = useState<readonly PlaceType[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<PlaceType | null>(
        null
    );

    useEffect(() => {
        if (oldGroupData == null) {
            setOldGroupData(groupData);
        }
    }, []);

    const handleClose = () => {
        setIsOpen(false);
        setIsEditing(false);
    };

    const handleEditClick = () => {
        setIsEditing(!isEditing);
        setEditableScreen(screen);
        setGroupData(oldGroupData);
    };

    const handleInputChange = (
        field: keyof VcpScreen,
        value: any,
        subField?: string
    ) => {
        setEditableScreen((prev) => {
            const currentFieldValue = prev[field];

            if (subField) {
                const updatedFieldValue = {
                    ...(typeof currentFieldValue === 'object' &&
                    currentFieldValue !== null
                        ? currentFieldValue
                        : {}),
                    [subField]: value
                };

                return {
                    ...prev,
                    [field]: updatedFieldValue
                };
            }

            return {
                ...prev,
                [field]: value !== undefined ? value : {}
            };
        });
    };

    const handleSave = async () => {
        try {
            if (editableScreen.group?.id != groupData?.id) {
                editableScreen.group = await getGroupRef(groupData?.id ?? '');
            }

            let updatedScreen = {
                ...editableScreen,
                updatedAt: Date.now()
            };

            if (selectedAddress) {
                updatedScreen.location.address = selectedAddress.description;
                updatedScreen.location.lat =
                    selectedAddress.geometry.location.lat;
                updatedScreen.location.lon =
                    selectedAddress.geometry.location.lng;
            }

            await updateGhostScreen(screen.id ?? '', updatedScreen);

            setScreens((prevScreens) => {
                if (!prevScreens) return prevScreens;

                return prevScreens.map((s) =>
                    s.screen_id === screen.screen_id
                        ? { ...s, ...updatedScreen }
                        : s
                );
            });
            setEditableScreen(updatedScreen);
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating screen:', error);
        }
    };

    return (
        <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
            <GoogleMapsComponent />
            <Box sx={{ bgcolor: '#f0f2f5' }}>
                <IconButton
                    onClick={handleClose}
                    size="large"
                    sx={{ width: '50px', float: 'right', mt: 1, mr: 1 }}
                >
                    <CloseIcon />
                </IconButton>
            </Box>
            <DialogTitle>
                {`Screen Details: ${screen.type ?? screen?.deviceInfo?.type}-${
                    screen.screen_id ?? screen.id
                }`}
                {screen.type === 'gs' && (
                    <Box sx={{ display: 'flex', float: 'right' }}>
                        <Button
                            onClick={handleEditClick}
                            variant="contained"
                            sx={{ mr: 2 }}
                        >
                            {isEditing ? 'Cancel' : 'Edit'}
                        </Button>
                        <Button
                            onClick={handleSave}
                            variant="contained"
                            disabled={!isEditing}
                        >
                            Save
                        </Button>
                    </Box>
                )}
            </DialogTitle>
            <Box sx={{ padding: 3 }}>
                <Typography variant="h6" gutterBottom>
                    General Information {isEditing && '(public information)'}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        {isEditing ? (
                            <Box sx={{ mb: 1 }}>
                                <AddresAutocomplete
                                    value={valueAddress}
                                    setValue={setValueAddress}
                                    inputValue={inputValue}
                                    setInputValue={setInputValue}
                                    options={options}
                                    setOptions={setOptions}
                                    setSelectedAddress={setSelectedAddress}
                                    defaultValue={
                                        editableScreen.location.address
                                    }
                                />
                            </Box>
                        ) : (
                            <Typography variant="body1">
                                <strong>Address:</strong>{' '}
                                {editableScreen.location.address ?? 'N/A'}
                            </Typography>
                        )}
                        <EditableField
                            label="Info"
                            value={editableScreen.note}
                            field="note"
                            isEditing={isEditing}
                            handleInputChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <EditableField
                            label="Resolution"
                            value={editableScreen?.display?.resolution}
                            field="display"
                            subField="resolution"
                            isEditing={isEditing}
                            handleInputChange={handleInputChange}
                        />
                        <EditableField
                            label="Orientation"
                            value={editableScreen?.display?.orientation}
                            field="display"
                            subField="orientation"
                            isEditing={isEditing}
                            handleInputChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <Typography variant="body1">
                    <strong>Latitude & Longitude:</strong>{' '}
                    {screen.location.lat && screen.location.lon
                        ? `${screen.location.lat}, ${screen.location.lon}`
                        : 'N/A'}
                </Typography>

                <Divider sx={{ my: 2 }} />
                {!groupData && (
                    <>
                        <Typography variant="h6" gutterBottom>
                            Device Information
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="body1">
                                    <strong>Name:</strong>{' '}
                                    {screen.deviceInfo?.name ?? 'N/A'}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Type:</strong>{' '}
                                    {screen.deviceInfo?.type ?? 'N/A'}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Platform:</strong>{' '}
                                    {screen.deviceInfo?.platform ?? 'N/A'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">
                                    <strong>MAC Address:</strong>{' '}
                                    {screen.deviceInfo?.mac ?? 'N/A'}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Paired:</strong>{' '}
                                    {screen.deviceInfo?.paired ? 'Yes' : 'No'}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Note:</strong>{' '}
                                    {screen.deviceInfo?.note ?? 'N/A'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider sx={{ my: 2 }} />
                        <Typography variant="h6" gutterBottom>
                            Status
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="body1">
                                    <strong>Active:</strong>{' '}
                                    {screen.status?.isActive ? 'Yes' : 'No'}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Online:</strong>{' '}
                                    {screen.status?.isOnline ? 'Yes' : 'No'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body1">
                                    <strong>Busy:</strong>{' '}
                                    {screen.status?.isBusy ? 'Yes' : 'No'}
                                </Typography>
                                <Typography variant="body1">
                                    <strong>Last Active:</strong>{' '}
                                    {screen.status?.lastActive
                                        ? new Date(
                                              parseInt(
                                                  screen.status.lastActive,
                                                  10
                                              )
                                          ).toLocaleString()
                                        : 'N/A'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                )}

                {groupData && (
                    <>
                        <Typography variant="h6" gutterBottom>
                            Additional Details{' '}
                            {isEditing && '(non-public information)'}
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {groupData &&
                                    (isEditing ? (
                                        <GroupSelect
                                            groupData={groupData}
                                            setGroupData={setGroupData}
                                        />
                                    ) : (
                                        <Typography variant="body1">
                                            <strong>Group:</strong>{' '}
                                            <div
                                                style={{
                                                    display: 'inline-flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        width: '12px',
                                                        height: '12px',
                                                        borderRadius: '50%',
                                                        backgroundColor:
                                                            groupData.color,
                                                        marginRight: '4px'
                                                    }}
                                                ></div>
                                                {groupData.name}
                                            </div>
                                        </Typography>
                                    ))}
                            </Grid>
                            {screen.createdBy && (
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        <strong>Created By:</strong>{' '}
                                        {screen.createdBy}
                                    </Typography>
                                </Grid>
                            )}
                            {screen.createdAt && (
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        <strong>Created At:</strong>{' '}
                                        {new Date(
                                            screen.createdAt
                                        ).toLocaleString()}
                                    </Typography>
                                </Grid>
                            )}
                            {screen.updatedAt && (
                                <Grid item xs={6}>
                                    <Typography variant="body1">
                                        <strong>Updated At:</strong>{' '}
                                        {new Date(
                                            editableScreen.updatedAt
                                        ).toLocaleString()}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}
            </Box>
        </Dialog>
    );
}
