import { Link } from '@mui/material';

const extractURLs = (text: string): { url: string; index: number }[] => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = [];
    let match;
    while ((match = urlRegex.exec(text)) !== null) {
        matches.push({ url: match[0], index: match.index });
    }
    return matches;
};

export const renderTextWithLinks = (text: string) => {
    const matches = extractURLs(text);

    if (matches.length === 0) {
        return text;
    }

    const parts: JSX.Element[] = [];
    let lastIndex = 0;

    matches.forEach(({ url, index }) => {
        if (lastIndex < index) {
            parts.push(
                <span key={lastIndex}>{text.slice(lastIndex, index)}</span>
            );
        }
        parts.push(
            <Link
                key={index}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: '0 4px' }}
            >
                {url}
            </Link>
        );
        lastIndex = index + url.length;
    });

    if (lastIndex < text.length) {
        parts.push(<span key={lastIndex}>{text.slice(lastIndex)}</span>);
    }

    return parts;
};
