import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteGhostScreen } from '../../service/ghost-screen-service';
import { useAuth } from '../../contexts/authContext';
import { Group } from '../../types/group';
import { VcpScreen } from '../../types/vcp-screen';
import InfoIcon from '@mui/icons-material/Info';
import { renderTextWithLinks } from './render-text-with-links';

interface Props {
    screen: VcpScreen;
    screens: VcpScreen[];
    setScreens: React.Dispatch<React.SetStateAction<VcpScreen[]>>;
    groupData: Group | null;
    status: 'Busy' | 'occupied' | 'Available' | 'on-demand';
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MarkerPopup(props: Props) {
    const { screen, screens, setScreens, groupData, isOpen, setIsOpen } = props;
    const { userLoggedIn } = useAuth();

    const handleOpenEditMarkerDialog = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Box>
                <Typography variant="button">{`${
                    screen.type ?? screen?.deviceInfo?.type
                }-${screen.screen_id ?? screen.id}`}</Typography>
                {userLoggedIn && (
                    <>
                        {screen!.status!.isBusy && (
                            <IconButton
                                size="small"
                                sx={{ float: 'right' }}
                                onClick={async () => {
                                    await deleteGhostScreen(screen.screen_id);
                                    const filteredOutScreens: VcpScreen[] =
                                        screens.filter(
                                            (s) =>
                                                s.screen_id !== screen.screen_id
                                        );
                                    setScreens(filteredOutScreens);
                                }}
                            >
                                <DeleteIcon sx={{ fontSize: '16px' }} />
                            </IconButton>
                        )}
                        <IconButton
                            size="small"
                            sx={{ float: 'right' }}
                            onClick={handleOpenEditMarkerDialog}
                        >
                            <InfoIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                    </>
                )}
            </Box>
            <br />
            Address: {screen.location.address}
            <br />
            Status: {props.status}
            {screen?.display && (
                <>
                    <br />
                    Screen: {screen.display?.resolution}
                    {' - '}
                    {screen.display?.orientation}
                </>
            )}
            {userLoggedIn && (
                <div>
                    Group:{' '}
                    <div style={{ display: 'inline-flex' }}>
                        <div
                            style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '50px',
                                backgroundColor: groupData?.color,
                                marginRight: '4px'
                            }}
                        ></div>
                        {groupData?.name}
                    </div>
                </div>
            )}
            <br />
            Info: {renderTextWithLinks(screen?.note ?? 'N/A') ?? 'N/A'}
        </>
    );
}
