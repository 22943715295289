import { AppBar, Box, Link, Toolbar, Typography } from '@mui/material';
import React from 'react';
import { useAuth } from '../contexts/authContext';
import { doSignOut } from '../firebase/auth';

export default function Navbar() {
    const { userLoggedIn } = useAuth();

    return (
        <AppBar position="sticky" sx={{ m: 0, padding: 0 }}>
            <Toolbar
                disableGutters
                variant="dense"
                sx={{ justifyContent: 'space-between' }}
            >
                <Typography variant="h6" fontSize={'2.5wv'} paddingLeft={2}>
                    VCP SCREENS
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-evenly',
                        paddingRight: 2,
                        gap: '25px',
                        mr: 2
                    }}
                >
                    <Link href="/">
                        <Typography sx={{ color: 'White' }}>Map</Typography>
                    </Link>
                    <Link href="/about">
                        <Typography sx={{ color: 'White' }}>About</Typography>
                    </Link>
                    {userLoggedIn && (
                        <Link href="/" onClick={() => doSignOut()}>
                            <Typography sx={{ color: 'White' }}>
                                Logout
                            </Typography>
                        </Link>
                    )}
                    {/* {!userLoggedIn && (
                        <Link href="/login">
                            <Typography sx={{ color: 'White' }}>
                                Login
                            </Typography>
                        </Link>
                    )} */}
                </Box>
            </Toolbar>
        </AppBar>
    );
}
