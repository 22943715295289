import { MapLocation } from '../types/location';

export async function getUserCountryCode(lat: number, lng: number) {
    const reverseResponse = await fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
    );

    if (!reverseResponse.ok) {
        throw new Error('Failed to fetch country data');
    }

    const reverseData = await reverseResponse.json();
    const countryCode: string = reverseData.address.country_code?.toUpperCase();
    return countryCode;
}

export async function getCountryData(countryCode: string) {
    const countryResponse = await fetch(
        `https://nominatim.openstreetmap.org/search?country=${countryCode}&format=json&limit=1`
    );

    if (!countryResponse.ok) {
        throw new Error('Failed to fetch country center data');
        return [];
    }

    const countryData: MapLocation[] = await countryResponse.json();
    return countryData;
}
