import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import VcpMarker from '../components/markers/marker';
import { AdminDrawer } from '../components/admin-panel/admin-drawer';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
} from '@mui/material';
import AddOnePoint from '../map-functions/add-screen-option';
import { useAuth } from '../contexts/authContext';
import AddCirclePoints from '../map-functions/add-screens-circle-option';
import AddCustomAreaPoints from '../map-functions/add-screens-custom-area-option';
import { useScreensData } from '../hooks/use-screens-data';
import MarkCustomAreaPoints from '../map-functions/mark-screens-custom-area-option';
import FlyToScreen from '../map-functions/fly-to-option';
import UserLocationCenter from '../map-functions/user-location-center';
import SaveMapStateToCookies from '../map-functions/save-map-state-to-cookies';
import { useMapState } from '../contexts/mapStateContext';
import TrackMapView from '../map-functions/track-map-view';
import { VcpScreen } from '../types/vcp-screen';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import {
    editMapsType,
    fetchMapsType
} from '../service/google-map-view-service';

function MapPage() {
    const { mapState } = useMapState();

    const { screens, loading, setScreens } = useScreensData();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const [isAddingPoint, setIsAddingPoint] = useState(false);
    const [isAddingPointCircle, setIsAddingPointCircle] = useState(false);
    const [isAddingPointCustomArea, setIsAddingPointCustomArea] =
        useState(false);
    const [isMarkingPoints, setIsMarkingPoints] = useState(false);
    const [note, setNote] = useState<string>('');

    const [numberOfPoints, setNumberOfPoints] = useState(0);

    const [flyTo, setFlyTo] = useState<L.LatLngExpression | null>(null);

    const { userLoggedIn } = useAuth();

    const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };

    const [useGoogleMaps, setUseGoogleMaps] = useState<boolean>(false);
    const [googleMapsView, setGoogleMapsView] = useState<
        'hybrid' | 'roadmap' | 'satellite' | 'terrain'
    >('hybrid');

    const handleGoogleMapSwitch = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setUseGoogleMaps(event.target.checked);
        await editMapsType('mapViewType', event.target.checked);
    };

    const handleMapTypeChange: any = (
        event: React.ChangeEvent<{ value: unknown }>
    ) => {
        setGoogleMapsView(event.target.value as any);
    };

    const getMapViewType = async () => {
        const useGoogle = await fetchMapsType('mapViewType');
        console.log(useGoogle.useGoogle);
        if (useGoogle) {
            setUseGoogleMaps(useGoogle.useGoogle);
        }
    };

    useEffect(() => {
        getMapViewType();
    }, []);

    if (loading) {
        return (
            <Box sx={{ width: '100%', height: '70vh', display: 'flex' }}>
                <CircularProgress sx={{ margin: 'auto' }} size={100} />
            </Box>
        );
    }

    return (
        <div
            id="map"
            style={{
                height: '93vh',
                margin: 0,
                padding: 0,
                position: 'relative'
            }}
        >
            <MapContainer
                center={mapState?.center}
                zoom={mapState?.zoom}
                scrollWheelZoom={true}
                style={{ height: '100%', width: '100%' }}
                closePopupOnClick={true}
            >
                {!useGoogleMaps && (
                    <TileLayer
                        attribution="&copy; OpenStreetMap contributors"
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                )}

                {useGoogleMaps && googleMapsView === 'hybrid' && (
                    <ReactLeafletGoogleLayer
                        apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
                        type={'hybrid'}
                    />
                )}
                {useGoogleMaps && googleMapsView === 'roadmap' && (
                    <ReactLeafletGoogleLayer
                        apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
                        type={'roadmap'}
                    />
                )}
                {useGoogleMaps && googleMapsView === 'satellite' && (
                    <ReactLeafletGoogleLayer
                        apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
                        type={'satellite'}
                    />
                )}
                {useGoogleMaps && googleMapsView === 'terrain' && (
                    <ReactLeafletGoogleLayer
                        apiKey={process.env.REACT_APP_FIREBASE_API_KEY}
                        type={'terrain'}
                    />
                )}

                <MarkerClusterGroup chunkedLoading>
                    {screens?.map((screen: VcpScreen) => (
                        <VcpMarker
                            key={`${screen.type ?? screen?.deviceInfo?.type}-${
                                screen.screen_id ?? screen.id
                            }`}
                            screen={screen}
                            screens={screens}
                            setScreens={setScreens}
                        />
                    ))}
                </MarkerClusterGroup>
                <SaveMapStateToCookies />
                <TrackMapView />
                {userLoggedIn && screens && (
                    <>
                        <AddOnePoint
                            screens={screens}
                            setScreens={setScreens}
                            isAddingPoint={isAddingPoint}
                            setIsAddingPoint={setIsAddingPoint}
                            note={note}
                        />
                        <AddCirclePoints
                            isAddingPointCircle={isAddingPointCircle}
                            screens={screens}
                            setScreens={setScreens}
                            setIsAddingPointCircle={setIsAddingPointCircle}
                            numberOfPoints={numberOfPoints}
                            note={note}
                        />
                        <AddCustomAreaPoints
                            isAddingPointCustomArea={isAddingPointCustomArea}
                            screens={screens}
                            setScreens={setScreens}
                            setIsAddingPointCustomArea={
                                setIsAddingPointCustomArea
                            }
                            numberOfPoints={numberOfPoints}
                            note={note}
                        />
                        <MarkCustomAreaPoints
                            screens={screens}
                            setScreens={setScreens}
                            isMarkingPoints={isMarkingPoints}
                            setIsMarkingPoints={setIsMarkingPoints}
                        />
                        <FlyToScreen
                            flyTo={flyTo}
                            setFlyTo={setFlyTo}
                            showTemporaryScreenMarker={true}
                        />
                    </>
                )}
                <UserLocationCenter />
            </MapContainer>

            <Box
                sx={{
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    zIndex: 1000
                }}
            >
                {userLoggedIn && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={toggleDrawer(true)}
                    >
                        Open Admin Panel
                    </Button>
                )}
                {useGoogleMaps && (
                    <Box
                        sx={{
                            position: 'fixed',
                            bottom: 20,
                            left: 0,
                            zIndex: 1000,
                            background: 'white',
                            padding: 2,
                            borderRadius: 2,
                            boxShadow: 2
                        }}
                    >
                        <FormControl component="fieldset" margin="none">
                            <FormLabel component="legend">Map Type</FormLabel>
                            <RadioGroup
                                aria-label="map-type"
                                name="map-type"
                                value={googleMapsView}
                                onChange={handleMapTypeChange}
                            >
                                <FormControlLabel
                                    value={'hybrid'}
                                    control={<Radio size="small" />}
                                    label="Hybrid"
                                />
                                <FormControlLabel
                                    value={'roadmap'}
                                    control={<Radio size="small" />}
                                    label="Roadmap"
                                />
                                <FormControlLabel
                                    value={'satellite'}
                                    control={<Radio size="small" />}
                                    label="Satellite"
                                />
                                <FormControlLabel
                                    value={'terrain'}
                                    control={<Radio size="small" />}
                                    label="Terrain"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                )}
            </Box>

            {userLoggedIn && screens && (
                <AdminDrawer
                    open={drawerOpen}
                    toggleDrawer={toggleDrawer}
                    screens={screens}
                    setScreens={setScreens}
                    isAddingPoint={isAddingPoint}
                    setIsAddingPoint={setIsAddingPoint}
                    isAddingPointCircle={isAddingPointCircle}
                    setIsAddingPointCircle={setIsAddingPointCircle}
                    numberOfPoints={numberOfPoints}
                    setNumberOfPoints={setNumberOfPoints}
                    isAddingPointCustomArea={isAddingPointCustomArea}
                    setIsAddingPointCustomArea={setIsAddingPointCustomArea}
                    isMarkingPoints={isMarkingPoints}
                    setIsMarkingPoints={setIsMarkingPoints}
                    flyTo={flyTo}
                    setFlyTo={setFlyTo}
                    note={note}
                    setNote={setNote}
                    useGoogleMaps={useGoogleMaps}
                    handleGoogleMapSwitch={handleGoogleMapSwitch}
                />
            )}
        </div>
    );
}

export default MapPage;
