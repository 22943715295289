import { db } from '../firebase/firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { HTML_CONTENT_COLLECTION } from '../utils/collection-names';

const fetchMapsType = async (documentId: string): Promise<any> => {
    try {
        const docRef = doc(db, HTML_CONTENT_COLLECTION, documentId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            const rawHtml = docSnap.data();
            return rawHtml;
        } else {
            console.error('Document not found.');
            return '';
        }
    } catch (error) {
        console.error('Error fetching HTML content:', error);
        throw error;
    }
};
const editMapsType = async (
    documentId: string,
    newMapType: boolean
): Promise<void> => {
    try {
        const docRef = doc(db, HTML_CONTENT_COLLECTION, documentId);
        await setDoc(docRef, { useGoogle: newMapType }, { merge: true });
        console.log('newMapType updated successfully.');
    } catch (error) {
        console.error('Error editing newMapType:', error);
        throw error;
    }
};

export { fetchMapsType, editMapsType };
