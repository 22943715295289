import { useEffect, useState } from 'react';
import { GhostScreen } from '../types/ghost-screen';
import { getGhostScreensInTile } from '../service/ghost-screen-service';
import { VcpScreen } from '../types/vcp-screen';
import { getAllScreens } from '../service/vcp-screen-service';
import { geocodeAddress } from '../service/google-places-api';
import { useMapState } from '../contexts/mapStateContext';

export const useScreensData = () => {
    const { visibleTiles } = useMapState();

    const [screens, setScreens] = useState<(VcpScreen | GhostScreen)[]>([]);
    const [loading, setLoading] = useState(false);
    const [alreadyLoadedTiles, setAlreadyLoadedTiles] = useState<string[]>([]);

    const isLatLonValid = (lat: any, lng: any) => {
        return lat && lng && lat !== '' && lng !== '';
    };

    const getData = async () => {
        if (!visibleTiles) return;

        try {
            // Determine tiles that need loading
            const newTilesToLoad = visibleTiles.filter(
                (tile: any) =>
                    !alreadyLoadedTiles.includes(JSON.stringify(tile))
            );

            // Fetch Ghost screens for each new tile
            const tilePromises = newTilesToLoad.map(async (tile: any) => {
                const ghostScreens = await getGhostScreensInTile(tile, screens);
                return { tile, ghostScreens };
            });

            const tileResults = await Promise.all(tilePromises);

            let ghostPoints: GhostScreen[] = [];
            if (tileResults)
                tileResults.forEach(({ tile, ghostScreens }) => {
                    ghostPoints = [...ghostPoints, ...ghostScreens];
                    setAlreadyLoadedTiles((prev) => [
                        ...prev,
                        JSON.stringify(tile)
                    ]);
                });

            // Resolve missing lat/lon by geocoding address
            const promises = ghostPoints.map(async (screen) => {
                if (
                    !isLatLonValid(
                        screen?.location?.lat,
                        screen?.location?.lon
                    ) &&
                    screen.type !== 'gs' &&
                    screen?.location?.address
                ) {
                    const coords = await geocodeAddress(
                        screen?.location?.address ?? ''
                    );
                    if (coords) {
                        screen.location.lat = coords.lat;
                        screen.location.lon = coords.lon;
                    }
                }
            });

            await Promise.all(promises);

            // Deduplicate Ghost screens and merge with the current state
            const uniqueScreens = Array.from(
                new Map(
                    ghostPoints.map((screen) => [screen.screen_id, screen])
                ).values()
            );

            setScreens((prevScreens) => [
                ...prevScreens,
                ...uniqueScreens.filter(
                    (screen) =>
                        !prevScreens.some(
                            (s) => s.screen_id === screen.screen_id
                        )
                )
            ]);
        } catch (error) {
            console.error('Error fetching Ghost screens:', error);
        }
    };

    // Fetch all VCP screens only once and add to the unified state
    useEffect(() => {
        const fetchAllScreens = async () => {
            setLoading(true);
            try {
                const vcpScreens = await getAllScreens();
                getData();
                setScreens((prevScreens) => [
                    ...prevScreens,
                    ...vcpScreens.filter(
                        (screen) =>
                            !prevScreens.some(
                                (s) => s.screen_id === screen.screen_id
                            )
                    )
                ]);
            } catch (error) {
                console.error('Error fetching all VCP screens:', error);
            }
            setLoading(false);
        };

        // fetchAllScreens();
    }, []);

    // Fetch Ghost screens based on visible tiles
    useEffect(() => {
        // Only fetch data if there are new tiles to load
        const hasNewTiles = visibleTiles.some(
            (tile: any) => !alreadyLoadedTiles.includes(JSON.stringify(tile))
        );

        if (hasNewTiles) {
            getData();
        }
    }, [visibleTiles]);
    // console.log(`SCREENS: ${screens.length}`);

    return { screens, loading, setScreens };
};
