import React from 'react';
import L, { LatLng, Polygon } from 'leaflet';
import { GhostScreen } from '../types/ghost-screen';
import {
    getLastGhostScreenId,
    saveManyGhostScreens
} from '../service/ghost-screen-service';
import { usePolygonDrawing } from '../hooks/use-polygon-drawing';
import { isPointInPolygon } from '../utils/is-point-in-pollygon';
import { useAuth } from '../contexts/authContext';
import { createGhostScreen } from '../utils/new-ghost-marker';
import { useGroups } from '../contexts/groupsContext';

interface Props {
    screens: GhostScreen[];
    setScreens: React.Dispatch<React.SetStateAction<GhostScreen[]>>;
    isAddingPointCustomArea: boolean;
    setIsAddingPointCustomArea: React.Dispatch<React.SetStateAction<boolean>>;
    numberOfPoints: number;
    note: string;
}

export default function AddCustomAreaPoints(props: Props) {
    const {
        screens,
        setScreens,
        isAddingPointCustomArea,
        setIsAddingPointCustomArea,
        numberOfPoints,
        note
    } = props;

    const { currentUser } = useAuth();
    const { selectedGroupRef } = useGroups();

    const generateGhostPoints = async (polygon: Polygon, count: number) => {
        const points = polygon.getLatLngs()[0] as LatLng[];
        const bounds = polygon.getBounds();
        const generatedPoints: GhostScreen[] = [];
        let attempts = 0;
        let idNumberIncr = 1;
        const idNumber: number | null = await getLastGhostScreenId();

        if (!selectedGroupRef) {
            alert('Select group!');
        }

        if (selectedGroupRef)
            while (generatedPoints.length < count && attempts < count * 10) {
                attempts++;
                const randomLat =
                    bounds.getSouthWest().lat +
                    Math.random() *
                        (bounds.getNorthEast().lat - bounds.getSouthWest().lat);
                const randomLng =
                    bounds.getSouthWest().lng +
                    Math.random() *
                        (bounds.getNorthEast().lng - bounds.getSouthWest().lng);
                const randomPoint = L.latLng(randomLat, randomLng);

                if (isPointInPolygon(randomPoint, points)) {
                    if (idNumber && selectedGroupRef) {
                        const newScreen: GhostScreen = createGhostScreen(
                            randomLat,
                            randomLng,
                            idNumber + idNumberIncr,
                            selectedGroupRef,
                            currentUser.email,
                            note
                        );

                        generatedPoints.unshift(newScreen);
                        idNumberIncr++;
                    } else {
                        // alert('Firestore error.');
                    }
                }
            }

        setScreens([...screens, ...generatedPoints]);
        saveManyGhostScreens(generatedPoints);
        idNumberIncr = 0;
    };

    usePolygonDrawing({
        isDrawingEnabled: isAddingPointCustomArea,
        setIsDrawingEnabled: setIsAddingPointCustomArea,
        onPolygonComplete: (polygon) => {
            generateGhostPoints(polygon, numberOfPoints);
        }
    });

    return null;
}
