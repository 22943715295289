import L, { IconOptions } from 'leaflet';

export const createLocationIcon = (
    color: string,
    borderColor: string,
    isMarked?: boolean
): L.Icon<IconOptions> => {
    const svg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="-1.6 -1.6 19.20 19.20">
            <path fill="${color}" stroke="${borderColor}" stroke-width="${
        isMarked ? 1.5 : 0.3
    }" fill-rule="evenodd" clip-rule="evenodd"
                d="M3.37892 10.2236L8 16L12.6211 10.2236C13.5137 9.10788 14 7.72154 14 6.29266V6C14 2.68629 11.3137 0 8 0C4.68629 0 2 2.68629 2 6V6.29266C2 7.72154 2.4863 9.10788 3.37892 10.2236ZM8 8C9.10457 8 10 7.10457 10 6C10 4.89543 9.10457 4 8 4C6.89543 4 6 4.89543 6 6C6 7.10457 6.89543 8 8 8Z"
            />
        </svg>
    `;
    const svgUrl = `data:image/svg+xml;base64,${btoa(svg)}`;

    return new L.Icon({
        iconUrl: svgUrl,
        iconSize: isMarked ? [50, 57] : [40, 47]
    });
};
