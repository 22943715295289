import React, { useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const MapContext = React.createContext();

export function useMapState() {
    return useContext(MapContext);
}

export function MapStateProvider({ children }) {
    const mapStateCookie = Cookies.get('mapState');
    const defaultState = mapStateCookie
        ? JSON.parse(mapStateCookie)
        : { center: [30, 0], zoom: 2 };

    const [mapState, setMapState] = useState(defaultState);
    const [bounds, setBounds] = useState(null);
    const [tiles, setTiles] = useState([]);
    const [visibleTiles, setVisibleTiles] = useState([]);

    const setNewMapState = (center, zoom) => {
        const newState = JSON.stringify({ center, zoom });

        if (mapState != { center, zoom }) {
            setMapState({ center, zoom });

            Cookies.set('mapState', newState, {
                expires: 7
            });
        }
    };

    const generateTiles = (tileSize) => {
        const tiles = [];
        for (let lat = -90; lat < 90; lat += tileSize) {
            for (let lon = -180; lon < 180; lon += tileSize) {
                tiles.push({
                    latMin: lat,
                    latMax: lat + tileSize,
                    lonMin: lon,
                    lonMax: lon + tileSize
                });
            }
        }
        return tiles;
    };

    const calculateVisibleTiles = (currentBounds) => {
        if (!currentBounds || !tiles.length) return [];
        const { _southWest, _northEast } = currentBounds;

        const visible = tiles.filter(
            (tile) =>
                tile.latMax > _southWest.lat &&
                tile.latMin < _northEast.lat &&
                tile.lonMax > _southWest.lng &&
                tile.lonMin < _northEast.lng
        );

        return visible;
    };

    useEffect(() => {
        const tileSize = 10; // 10° x 10°
        setTiles(generateTiles(tileSize));
    }, []);

    useEffect(() => {
        if (bounds) {
            const newVisibleTiles = calculateVisibleTiles(bounds);
            setVisibleTiles(newVisibleTiles);
        }
    }, [bounds, tiles]);

    const value = {
        mapState,
        setNewMapState,
        bounds,
        setBounds,
        tiles,
        visibleTiles
    };
    return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
}
